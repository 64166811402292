import { sentenceCase } from "change-case";
import { Markdown } from "../components/Markdown/Markdown";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { CourseInfoFragment } from "../schema";

export function getCourseNameValueList(course: CourseInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique ID",
      value: course.id,
    },
    {
      name: "Is enabled",
      value: course.isEnabled ? "Yes" : "No",
    },
    {
      name: "Title",
      value: course.title,
    },
    {
      name: "Locale",
      value: course.locale ? sentenceCase(course.locale) : "n/a",
    },
    {
      name: "Description",
      value: <Markdown>{course.description}</Markdown>,
    },
    {
      name: "background",
      value: <img src={course.backgroundUrl} alt="Small background" style={{ width: "50rem" }} />,
    },
    {
      name: "Trailer Vimeo ID",
      value: course.trailerVimeoId,
    },
    {
      name: "Labels",
      value: course.labels ? course.labels.map((label) => label.title).join(", ") : "n/a",
    },
    {
      name: "Order weight",
      value: course.orderWeight,
    },
    {
      name: "Age restricted (18+)",
      value: course.isAdultOnly ? "Yes" : "No",
    },
  ];
}

import React, { useMemo } from "react";
import { CgDollar, CgFeed, CgUserList } from "react-icons/cg";
import {
  MdLabelOutline,
  MdOutlineArticle,
  MdOutlineAudiotrack,
  MdOutlineCategory,
  MdOutlineOndemandVideo,
  MdOutlinePersonalVideo,
  MdOutlinePoll,
  MdOutlineTopic,
  MdReviews,
  MdSupervisedUserCircle,
  MdOutlineCloudUpload,
} from "react-icons/md";
import { Navigate, Routes, Route, useParams } from "react-router-dom";
import { AppHeader } from "../../components/AppHeader/AppHeader";
import { Column } from "../../components/Column/Column";
import { MainMenuItemInfo, MainMenu, MainMenuItem, isMainMenuItem } from "../../components/MainMenu/MainMenu";
import { Row } from "../../components/Row/Row";
import { AdminViewParams, ADMIN_VIEW_PATH, AdminViewMenu, ViewerInfo } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminFeedItemCreateView } from "../AdminFeedItemCreateView/AdminFeedItemCreateView";
import { AdminFeedItemDetailsView } from "../AdminFeedItemDetailsView/AdminFeedItemDetailsView";
import { AdminFeedItemListView } from "../AdminFeedItemListView/AdminFeedItemListView";
import { AdminFeedPollCreateView } from "../AdminFeedPollCreateView/AdminFeedPollCreateView";
import { AdminFeedPollDetailsView } from "../AdminFeedPollDetailsView/AdminFeedPollDetailsView";
import { AdminFeedPollListView } from "../AdminFeedPollListView/AdminFeedPollListView";
import { AdminFeedTopicDetailsView } from "../AdminFeedTopicDetailsView/AdminFeedTopicDetailsView";
import { AdminFeedTopicListView } from "../AdminFeedTopicListView/AdminFeedTopicListView";

import { AdminPaymentDetailsView } from "../AdminPaymentDetailsView/AdminPaymentDetailsView";
import { AdminPaymentListView } from "../AdminPaymentListView/AdminPaymentListView";
import { AppReviewDetailsView } from "../AppReviewDetailsView/AppReviewDetailsView";
import { AppReviewsListView } from "../AppReviewsListView/AppReviewsListView";
import { ArticleDetailsView } from "../ArcticleDetailsView/ArcticleDetailsView";
import { ArticlesListView } from "../ArticleListView/ArticleListView";
import { AuthorDetailsView } from "../AuthorDetailsView/AuthorDetailsView";
import { AuthorListView } from "../AuthorListView/AuthorListView";
import { CategoriesListView } from "../CategoriesListView/CategoriesListView";
import { CategoryOrderView } from "../CategoriesListView/CategoryOrderView";
import { CategoryDetailsView } from "../CategoryDetailsView/CategoryDetailsView";
import { CourseDetailsView } from "../CourseDetailsView/CourseDetailsView";
import { CoursesListView } from "../CoursesListView/CoursesListView";
import { FileUploadDetailsView } from "../FileUploadDetailsView/FileUploadDetailsView";
import { FileUploadListView } from "../FileUploadListView/FileUploadListView";
import { GiftCardDetailsView } from "../GiftCardDetailsView/GiftCardDetailsView";
import { GiftCardListView } from "../GiftCardListView/GiftCardListView";
import { GuestUserListView } from "../GuestUserListView/GuestUserListView";
import { LabelDetailsView } from "../LabelDetailsView/LabelDetailsView";
import { LabelsListView } from "../LabelListView/LabelListView";
import { LessonDetailsView } from "../LessonDetailsView/LessonDetailsView";
import { LessonsListView } from "../LessonsListView/LessonsListView";
import { LoadingView } from "../LoadingView/LoadingView";
import { TrackDetailsView } from "../TrackDetailsView/TrackDetailsView";
import { TrackListView } from "../TrackListView/TrackListView";
import { UserDetailsView } from "../UserDetailsView/UserDetailsView";
import { UserListView } from "../UserListView/UserListView";
import styles from "./AdminView.module.scss";

export interface AdminViewProps {
  viewer: ViewerInfo;
}

export const AdminView: React.FC<AdminViewProps> = (props) => {
  const params = useParams<AdminViewParams>();

  // list of main menu items
  const menus: MainMenuItem<AdminViewProps>[] = useMemo(
    () => [
      {
        title: "USERS",
      },
      {
        name: "users",
        title: "Users",
        icon: <CgUserList />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<UserListView viewer={viewer} />} />
            <Route path="user/:id/*" element={<UserDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "guests",
        title: "Guest Users",
        icon: <CgUserList />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "guests" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<GuestUserListView viewer={viewer} />} />
            {/* <Route path="user/:id/*" element={<UserDetailsView viewer={viewer} />} /> */}
          </Routes>
        ),
      },
      {
        title: "GIFT CARDS",
      },
      {
        name: "gift-cards",
        title: "Gift Cards",
        icon: <MdReviews />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "gift-cards" }),
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_GIFT_CARDS,
          UserScopeEnum.ADMIN_GIFT_CARDS_LIST,
        ],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<GiftCardListView viewer={viewer} />} />
            <Route path="gift-card/:id/*" element={<GiftCardDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        title: "REVIEWS",
      },
      {
        name: "app-reviews",
        title: "App reviews",
        icon: <MdReviews />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "app-reviews" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_REVIEWS, UserScopeEnum.ADMIN_REVIEWS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AppReviewsListView viewer={viewer} />} />
            <Route path="app-review/:id/*" element={<AppReviewDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      // {
      //   name: "track-reviews",
      //   title: "Track reviews",
      //   icon: <MdRateReview />,
      //   url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "track-reviews" }),
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_REVIEWS, UserScopeEnum.ADMIN_REVIEWS_LIST],
      //   content: ({ viewer }) => (
      //     <Routes>
      //       <Route index element={<TrackReviewsListView viewer={viewer} />} />
      //       <Route path="review/:id/*" element={<TrackReviewDetailsView viewer={viewer} />} />
      //     </Routes>
      //   ),
      // },
      {
        title: "JOURNEYS",
      },
      {
        name: "categories",
        title: "Categories",
        icon: <MdOutlineCategory />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "categories" }),
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_LIST,
        ],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<CategoriesListView viewer={viewer} />} />
            <Route path="order" element={<CategoryOrderView viewer={viewer} />} />
            <Route path="category/:id/*" element={<CategoryDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "tracks",
        title: "Tracks",
        icon: <MdOutlineAudiotrack />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "tracks" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<TrackListView viewer={viewer} />} />
            <Route path="track/:id/*" element={<TrackDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        title: "BLOGS & COURSES",
      },
      {
        name: "authors",
        title: "Authors",
        icon: <MdSupervisedUserCircle />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "authors" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_AUTHORS, UserScopeEnum.ADMIN_AUTHORS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AuthorListView viewer={viewer} />} />
            <Route path="author/:id/*" element={<AuthorDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "courses",
        title: "Masterclasses",
        icon: <MdOutlinePersonalVideo />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "courses" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<CoursesListView viewer={viewer} />} />
            <Route path="course/:id/*" element={<CourseDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "lessons",
        title: "Lessons",
        icon: <MdOutlineOndemandVideo />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "lessons" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LESSONS, UserScopeEnum.ADMIN_LESSONS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<LessonsListView viewer={viewer} />} />
            <Route path="lesson/:id/*" element={<LessonDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "labels",
        title: "Labels",
        icon: <MdLabelOutline />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "labels" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<LabelsListView viewer={viewer} />} />
            <Route path="label/:id/*" element={<LabelDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "articles",
        title: "Blog articles",
        icon: <MdOutlineArticle />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "articles" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_ARTICLES, UserScopeEnum.ADMIN_ARTICLES_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<ArticlesListView viewer={viewer} />} />
            <Route path="article/:id/*" element={<ArticleDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        title: "Admin feed",
      },
      {
        name: "feed-topics",
        title: "Topics",
        icon: <MdOutlineTopic />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-topics" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_TOPIC_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AdminFeedTopicListView viewer={viewer} />} />
            <Route path="topics/:id/*" element={<AdminFeedTopicDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "feed-items",
        title: "Items",
        icon: <CgFeed />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-items" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_ITEM_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AdminFeedItemListView viewer={viewer} />} />
            <Route path="items/new" element={<AdminFeedItemCreateView viewer={viewer} />} />
            <Route path="items/:id/*" element={<AdminFeedItemDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        name: "feed-polls",
        title: "Polls",
        icon: <MdOutlinePoll />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-polls" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_POLL_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AdminFeedPollListView viewer={viewer} />} />
            <Route path="polls/new" element={<AdminFeedPollCreateView viewer={viewer} />} />
            <Route path="polls/:id/*" element={<AdminFeedPollDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      // {
      //   name: "comments",
      //   title: "Comments",
      //   icon: <MdOutlineComment />,
      //   url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-comments", page: "comments" }),
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_COMMENT_LIST],
      // },
      {
        title: "Payments",
      },
      {
        name: "payments",
        title: "Payments",
        icon: <CgDollar />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "payments" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_PAYMENTS, UserScopeEnum.ADMIN_PAYMENTS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<AdminPaymentListView viewer={viewer} />} />
            <Route path="payment/:id/*" element={<AdminPaymentDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      {
        title: "Media",
      },
      {
        name: "media",
        title: "Image uploads",
        icon: <MdOutlineCloudUpload />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "media" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FILES],
        content: ({ viewer }) => (
          <Routes>
            <Route index element={<FileUploadListView viewer={viewer} />} />
            <Route path="uploads/:id/*" element={<FileUploadDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
    ],
    [],
  );
  const defaultMenu = menus[1] as MainMenuItemInfo<AdminViewProps>;
  const activeMenuInfo = getMenuByName(menus, params.menu);

  // redirect to default menu if none could by found by name
  if (!activeMenuInfo) {
    return <Navigate to={defaultMenu.name} />;
  }

  const activeItemIndex = menus.indexOf(activeMenuInfo);

  // get logged in user
  const { viewer } = props;

  if (!viewer) {
    return <LoadingView />;
  }

  return (
    <Column cover>
      <AppHeader viewer={viewer} />
      <Row expanded crossAxisAlignment="stretch">
        <Column overflow className={styles.menu}>
          <MainMenu items={menus} activeItemIndex={activeItemIndex} viewerscopes={viewer.scopes} />
        </Column>
        <Column expanded className={styles.content}>
          {activeMenuInfo.content ? activeMenuInfo.content(props) : null}
        </Column>
      </Row>
    </Column>
  );
};

function getMenuByName<T>(menus: MainMenuItem<T>[], name?: AdminViewMenu): MainMenuItemInfo<T> | undefined {
  const item = menus.find((menu) => isMainMenuItem(menu) && menu.name === name);

  if (isMainMenuItem(item)) {
    return item;
  }
}

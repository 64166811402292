import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteCourseModal } from "../../modals/DeleteCourseModal/DeleteCourseModal";
import { ToggleEnabledModal, ToggleType } from "../../modals/ToggleEnabledModal/ToggleEnabledModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CollaborationTypeEnum,
  CourseByIdQueryResult,
  LabelTypeEnum,
  LocaleEnum,
  useAdminDeleteCourseMutation,
  useAdminUpdateCourseInfoMutation,
  useAdminToggleCourseEnabledMutation,
  useAuthorsQuery,
  useCourseByIdQuery,
  useLabelsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getAuthorNameValueList } from "../../services/getAuthorNameValueList";
import { getCourseNameValueList } from "../../services/getCourseNamedValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

export type CourseByIdQueryCourseInfo = NonNullable<CourseByIdQueryResult["data"]>["admin"]["course"];

export interface CourseDetailsViewProps {
  viewer: ViewerInfo;
}

export const CourseDetailsView: React.FC<CourseDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: courseData,
    loading: courseLoading,
    error: courseError,
  } = useCourseByIdQuery({
    variables: {
      courseId: params.id ?? "",
    },
  });

  const course = courseData?.admin.course;

  if (courseError) {
    return <ErrorView title="Loading masterclass info failed" error={courseError} />;
  }

  if (courseLoading || !course) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <CourseDetailsHeader course={course} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<CourseDetailsInfo course={course} viewer={viewer} />} />
        <Route path="edit" element={<CourseDetailsEdit course={course} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface CourseProps extends FlexProps {
  course: CourseByIdQueryCourseInfo;
  viewer: ViewerInfo;
}

export const CourseDetailsHeader: React.FC<CourseProps> = ({ course, ...rest }) => {
  const navigate = useNavigate();
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const [isDeleteCourseModalOpen, setIsDeleteCourseModalOpen] = useState(false);
  const [adminDeleteCourse, adminDeleteCourseResult] = useAdminDeleteCourseMutation();
  const [adminToggleCourseEnabled, adminToggleCourseEnabledResult] = useAdminToggleCourseEnabledMutation({
    onCompleted(data) {
      if (data.adminToggleEnabledCourse.id) {
        setToast({
          type: ToastType.SUCCESS,
          title: "Course enable/disable toggle",
          message: `Course has been ${data.adminToggleEnabledCourse.isEnabled ? "enabled" : "disabled"}`,
          isOpen: true,
        });
      }
    },
    onError(error) {
      setToast({
        type: ToastType.ERROR,
        title: "Course enable/disable toggle failed",
        message: `Course info was not changed ${error.message}`,
        isOpen: true,
      });
    },
  });
  const setToast = useSetRecoilState(toastState);

  const { modifier } = useAdminUrlInfo();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: course.isEnabled ? "Disable" : "Enable",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_DELETE],
        onClick: () => {
          setShowConfirmDisableModal(true);
        },
      },
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_DELETE],
        onClick: () => {
          setIsDeleteCourseModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "courses",
                  page: "course",
                  id: course.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_COURSES,
              UserScopeEnum.ADMIN_COURSES_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "courses",
                  page: "course",
                  id: course.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [course.id, course.isEnabled, isEditMode, navigate],
  );

  const { author } = course;

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      {
        label: "Author",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_AUTHORS, UserScopeEnum.ADMIN_AUTHORS_INFO],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "authors",
              page: "author",
              modifier: author.id,
            }),
          }),
      },
    ],
    [author.id, navigate],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Masterclass details</Title>
      </Header>

      <DeleteCourseModal
        open={isDeleteCourseModalOpen}
        lessons={course.lessons}
        onCancel={() => setIsDeleteCourseModalOpen(false)}
        onClickOutside={() => setIsDeleteCourseModalOpen(false)}
        loading={adminDeleteCourseResult.loading}
        error={adminDeleteCourseResult.error}
        onSubmit={async () => {
          const response = await adminDeleteCourse({
            variables: {
              courseIds: course.id,
            },
          });

          if (response.data) {
            setIsDeleteCourseModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "courses",
              }),
            });
          }
        }}
      />

      <ToggleEnabledModal
        type={ToggleType.course}
        open={showConfirmDisableModal}
        isEnabled={course.isEnabled}
        onCancel={() => setShowConfirmDisableModal(false)}
        onClickOutside={() => setShowConfirmDisableModal(false)}
        loading={adminToggleCourseEnabledResult.loading}
        error={adminToggleCourseEnabledResult.error}
        onSubmit={async () => {
          await adminToggleCourseEnabled({
            variables: {
              courseId: course.id,
            },
            refetchQueries: ["CourseById"],
            awaitRefetchQueries: true,
          });
          setShowConfirmDisableModal(false);
        }}
      />
    </>
  );
};

export const CourseDetailsInfo: React.FC<CourseProps> = ({ course, viewer }) => {
  const navigate = useNavigate();
  const { author } = course;

  const courseDetails = getCourseNameValueList(course);

  return (
    <WithCollaboration referenceId={course.id} type={CollaborationTypeEnum.COURSE} viewer={viewer}>
      <Title>Masterclass</Title>
      <NameValueList items={courseDetails} />

      <Title
        marginTop
        actions={[
          {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "authors",
                  page: "author",
                  id: author.id,
                }),
              }),
          },
        ]}
      >
        Author
      </Title>
      <NameValueList items={getAuthorNameValueList(author)} />
    </WithCollaboration>
  );
};

type EditCourseData = {
  title: string;
  description: string;
  background: string;
  trailerVimeoId: string;
  locale: LocaleEnum;
  authorId: string;
  orderWeight: string;
  isAdultOnly: string;
  comment: string;
  labelIds: string[];
};

export const CourseDetailsEdit: React.FC<CourseProps> = ({ course, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateCourseInfo, adminUpdateCourseInfoResult] = useAdminUpdateCourseInfoMutation();
  const { data: authorsData } = useAuthorsQuery();
  const { data: labelsData } = useLabelsQuery({
    variables: {
      filter: {
        type: [LabelTypeEnum.COURSE],
      },
    },
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "authorId",
        label: "Author name",
        options: authorsData
          ? authorsData.admin.authors.authors.map((author) => ({ value: author.id, label: author.name }))
          : [],
        defaultValue: course.author.id,
        rules: { required: "Please provide author" },
      },
      {
        field: "checkbox",
        name: "labelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
        defaultValue: course.labels.map((label) => label.id),
        rules: { required: "Please choose at least one label" },
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: course.title,
        rules: { required: "Please provide title" },
      },
      {
        field: "radio",
        name: "locale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: course.locale,
      },
      {
        field: "markdown",
        name: "description",
        label: "Description",
        defaultValue: course.description,
      },
      {
        field: "upload",
        type: "text",
        name: "backgroundImage",
        label: "Background image (leave blank to not change)",
      },
      {
        field: "text",
        name: "trailerVimeoId",
        label: "Trailer Vimeo ID",
        defaultValue: course.trailerVimeoId,
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Order weight (optional, larger shown later)",
        defaultValue: course.orderWeight.toString(),
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Adult only (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: course.isAdultOnly ? "true" : "false",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated Masterclass info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [
      authorsData,
      course.author.id,
      course.description,
      course.labels,
      course.locale,
      course.orderWeight,
      course.title,
      course.trailerVimeoId,
      course.isAdultOnly,
      labelsData,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditCourseData) => {
      const background = getFileUploadById("backgroundImage");

      const response = await adminUpdateCourseInfo({
        variables: {
          ...data,
          trailerVimeoId: data.trailerVimeoId,
          authorId: data.authorId.toString(),
          courseId: course.id,
          orderWeight: parseInt(data.orderWeight),
          background,
          labelIds: data.labelIds,
          isAdultOnly: data.isAdultOnly === "true",
        },
        refetchQueries: ["CollaborationById", "CourseById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "courses",
            page: "course",
            id: course.id,
          }),
        });
      }
    },
    [adminUpdateCourseInfo, course.id, navigate],
  );

  return (
    <WithCollaboration referenceId={course.id} type={CollaborationTypeEnum.COURSE} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Masterclass"
        error={adminUpdateCourseInfoResult.error}
        loading={adminUpdateCourseInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};

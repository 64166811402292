import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export enum ToggleType {
  course = "course",
  lesson = "lesson",
}

export interface ToggleEnabledModalProps extends ModalProps {
  type: ToggleType;
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  isEnabled: boolean;
  loading: boolean;
  title?: string;
}

export const ToggleEnabledModal: React.FC<ToggleEnabledModalProps> = ({
  type,
  title,
  onSubmit,
  onCancel,
  isEnabled,

  loading,
  ...rest
}) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];
  const modalTitle = `Confirm disable ${type}`;
  return (
    <Modal title={modalTitle} actions={modalActions} {...rest}>
      {title && (
        <P center marginBottom={3} strong>
          {title}
        </P>
      )}
      <P center marginBottom={5}>
        Are you sure you wish to {isEnabled ? "Disbale" : "Enable"} this {type}?
      </P>
    </Modal>
  );
};

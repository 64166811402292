import { paramCase, sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateCourseModal } from "../../modals/CreateCourseModal/CreateCourseModal";
import { DeleteCourseModal } from "../../modals/DeleteCourseModal/DeleteCourseModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  LocaleEnum,
  MatchModeEnum,
  useAdminDeleteCourseMutation,
  useCoursesQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { AdminViewProps } from "../AdminView/AdminView";
import { CourseByIdQueryCourseInfo } from "../CourseDetailsView/CourseDetailsView";
import { ErrorView } from "../ErrorView/ErrorView";

interface CoursesFilterData extends FilterBaseData {
  courseId: string;
  title: string;
  description: string;
  authorName: string;
  locale: LocaleEnum[];
  isEnabled: boolean | null;
}

export const CoursesListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<CoursesFilterData>((params) => ({
    courseId: params.courseId ?? "",
    title: params.title ?? "",
    authorName: params.authorName ?? "",
    description: params.description ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    locale: params.locale ? (params.locale.split(",") as LocaleEnum[]) : [],
    isEnabled: params.isEnabled !== undefined ? (params.isPinned === "true" ? true : false) : null,
  }));

  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useCoursesQuery({
    variables: {
      filter: {
        authorName: params.authorName.length > 0 ? params.authorName : undefined,
        courseId: params.courseId.length > 0 ? params.courseId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        description: params.description.length > 0 ? params.description : undefined,
        locale: params.locale.length > 0 ? params.locale : undefined,
        isEnabled: params.isEnabled ?? undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateCourseModalOpen, setIsCreateCourseModalOpen] = useState(false);
  const [isDeleteCourseModalOpen, setIsDeleteCourseModalOpen] = useState(false);
  const [confirmDeleteCourseIds, setConfirmDeleteCourseIds] = useState<string[]>([]);
  const [adminDeleteCourse, adminDeleteCourseResult] = useAdminDeleteCourseMutation({
    refetchQueries: ["Courses"],
    awaitRefetchQueries: true,
  });

  // pagination info
  const courses = useMemo(() => coursesData?.admin.courses.courses ?? [], [coursesData?.admin.courses.courses]);

  const total = coursesData?.admin.courses.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "courseId",
        label: "Masterclass id",
        defaultValue: params.courseId,
      },
      // TODO: find a solution to default value translations
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "text",
        type: "text",
        name: "description",
        label: "Description",
        defaultValue: params.description,
      },
      {
        field: "text",
        type: "text",
        name: "authorName",
        label: "Author name",
        defaultValue: params.authorName,
      },
      {
        field: "checkbox",
        name: "locale",
        label: "Locale",
        options: Object.keys(LocaleEnum).map((localeType) => {
          return { value: localeType, label: sentenceCase(localeType) };
        }),
        defaultValue: params.locale,
      },
      {
        field: "radio",
        name: "isEnabled",
        label: "Enabled",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.isEnabled !== null ? (params.isEnabled ? "true" : "false") : null,
      },
    ],
    [params.authorName, params.courseId, params.description, params.isEnabled, params.locale, params.title],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Description",
      },
      {
        label: "Author",
      },
      {
        label: "Locale",
      },
      {
        label: "Trailer Vimeo ID",
      },
      {
        label: "Labels",
      },
      {
        label: "Enabled",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      courses.map((course) => {
        const { author } = course;
        return {
          id: course.id,
          cells: [
            {
              content: course.title,
            },
            {
              content: limitTextLength(course.description),
            },
            {
              content: author.name,
              linkUrl: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "authors",
                page: "author",
                id: author.id,
              }),
            },
            {
              content: course.locale,
            },
            {
              content: course.trailerVimeoId,
            },
            {
              content: course.labels.map((label) => label.title).join(", "),
            },
            {
              content: course.isEnabled ? "Yes" : "No",
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_COURSES,
                UserScopeEnum.ADMIN_COURSES_INFO,
              ],
              onClick: (courseId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "courses",
                    page: "course",
                    id: courseId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_COURSES,
                UserScopeEnum.ADMIN_COURSES_UPDATE_INFO,
              ],
              onClick: (courseId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "courses",
                    page: "course",
                    id: courseId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_COURSES,
                UserScopeEnum.ADMIN_COURSES_DELETE,
              ],
              onClick: (courseIds) => {
                setConfirmDeleteCourseIds([courseIds]);
                setIsDeleteCourseModalOpen(true);
              },
            },
          ],
        };
      }),
    [courses, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_DELETE],
        onClick: (courseIds) => {
          setConfirmDeleteCourseIds(courseIds);
          setIsDeleteCourseModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create masterclass",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_CREATE],
        onClick: () => setIsCreateCourseModalOpen(true),
      },
    ],
    [],
  );

  const getPendingRemoveLessons = function () {
    const lessons: CourseByIdQueryCourseInfo["lessons"] = [];

    confirmDeleteCourseIds.forEach((id) => {
      const matchingCourse = courses.find((course) => course.id === id);

      if (matchingCourse) {
        lessons.push(...matchingCourse.lessons);
      }
    });

    return lessons;
  };

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: CoursesFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "courses" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (coursesError) {
    return <ErrorView title="Fetching Masterclass failed" error={coursesError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Masterclasses"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={coursesLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={coursesLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_COURSES,
            UserScopeEnum.ADMIN_COURSES_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "courses",
                page: "course",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "courses" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <DeleteCourseModal
          lessons={getPendingRemoveLessons()}
          open={isDeleteCourseModalOpen}
          onCancel={() => setIsDeleteCourseModalOpen(false)}
          onClickOutside={() => setIsDeleteCourseModalOpen(false)}
          loading={adminDeleteCourseResult.loading}
          error={adminDeleteCourseResult.error}
          onSubmit={async () => {
            const response = await adminDeleteCourse({
              variables: {
                courseIds: confirmDeleteCourseIds,
              },
            });

            if (response.data) {
              setIsDeleteCourseModalOpen(false);

              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "courses",
                }),
              });
            }
          }}
        />

        <CreateCourseModal open={isCreateCourseModalOpen} onClickOutside={() => setIsCreateCourseModalOpen(false)} />
      </View>
    </>
  );
};

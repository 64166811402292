import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { MatchModeEnum, ConditionModeEnum, UserScopeEnum, useGuestUsersQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

// fetch filtered and paginated list of guest users
interface GuestUsersFilterData extends FilterBaseData {
  oneSignalId: string;
  createdAfter: Date | null;
  createdBefore: Date | null;
}

// TODO: implement pagination "show all"
export const GuestUserListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<GuestUsersFilterData>((params) => ({
    oneSignalId: params.oneSignalId ?? "",
    createdAfter: params.createdAfter ? new Date(params.createdAfter) : null,
    createdBefore: params.createdBefore ? new Date(params.createdBefore) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  // load list of filtered and paginated entries
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useGuestUsersQuery({
    variables: {
      filter: {
        oneSignalId: params.oneSignalId.length > 0 ? params.oneSignalId : undefined,
        createdAfter: params.createdAfter ? formatApiDate(params.createdAfter) : undefined,
        createdBefore: params.createdBefore ? formatApiDate(params.createdBefore) : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // pagination info
  const guestUsers = useMemo(
    () => usersData?.admin.guestUsers.guestUsers ?? [],
    [usersData?.admin.guestUsers.guestUsers],
  );
  const total = usersData?.admin.guestUsers.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "oneSignalId",
        label: "OneSignal id",
        defaultValue: params.oneSignalId,
      },
      {
        field: "date",
        name: "createdAfter",
        label: "Created after",
        defaultValue: params.createdAfter,
      },
      {
        field: "date",
        name: "createdBefore",
        label: "Created before",
        defaultValue: params.createdBefore,
      },
    ],
    [params.oneSignalId, params.createdAfter, params.createdBefore],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "id",
      },
      {
        label: "oneSignalId",
      },
      {
        label: "deviceInfo",
      },
      {
        label: "created",
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      guestUsers.map((guestUser) => {
        // TODO: this will be used when restoring "Login as" functionality
        // const isRegularUser = user.roles.length === 1 && user.roles[0] === UserRoleEnum.USER;

        return {
          id: guestUser.id,
          cells: [
            {
              content: guestUser.id,
            },
            {
              content: guestUser.oneSignalId ? (
                <a
                  href={`https://dashboard.onesignal.com/apps/ad7dd136-cb4e-4a45-bb70-56b5ce92d8ff/users/${guestUser.oneSignalId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {guestUser.oneSignalId}
                </a>
              ) : (
                "n/a"
              ),
            },
            {
              content: guestUser.deviceInfo,
            },
            {
              content: DateTime.fromISO(guestUser.createdDate).toFormat("dd/MM/yyyy"),
              center: true,
            },
          ],
          actions: [],
        };
      }),
    [guestUsers],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: GuestUsersFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "guests" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (usersError) {
    return <ErrorView title="Fetching guest users failed" error={usersError} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Filter
          title="GuestUsers"
          fields={filters}
          viewerscopes={viewer.scopes}
          // loading={usersLoading}
          loading={false}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={usersLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          hideSelectRow
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_INFO]}
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "guests" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
    </>
  );
};
